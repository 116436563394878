@import 'scss/mixins/desktop';
@import 'scss/variables/colors';
@import 'scss/variables/fonts';

.h1 {
  color: $color-black;
  font-family: $font-Karla;
  font-weight: 300;
  font-size: 28px;
  margin: 18px auto 10px;
  text-align: center;
  letter-spacing: -1px;
  max-width: 600px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  @include desktop {
    font-size: 32px;
    text-align: center;
  }
}