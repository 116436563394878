@import 'scss/mixins/desktop';
@import 'scss/variables/colors';
@import 'scss/variables/fonts';

.pageDescription {
  color: $color-black;
  font-family: $font-Karla;
  font-weight: 300;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  text-align: center;
  margin: 0 auto 10px;
  max-width: 600px;

  @include desktop {
    font-size: 18px;
    line-height: 24px;
  }
}
