.navContainer {
  background-color: white;
  box-shadow: 0 0 4px white;
  opacity: 0.95;
  a, button {
    color: black;
  }

  a:hover{
    color: gray;
  }
}
