$color-white: white;
$color-black: #4a4a4a;
$color-light-grey: #cccccc;
$color-medium-grey: #999999;
$color-orange-pink: #e4a2a4;
$color-dark-magenta: #843c54;
$color-pink: #c98496;
$color-pale-pink: #eecbc7;

$color-disabled-light: $color-light-grey;
$color-diabled-dark: $color-medium-grey;
